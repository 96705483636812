@font-face {
  font-family: 'Abel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./Abel-Regular.ttf);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, #mapview .leaflet-popup-content-wrapper {
  font-family: 'Abel';
  font-weight: normal;

  background: #444;
  color: #eee;
}

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

a, #mapview .leaflet-popup-content-wrapper a {
  color: #8af;
  &:visited {
    color: #88f;
  }
}

p, .leaflet-popup-content-wrapper p {
  margin: 0;
}

#root>div {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.spacer {
  flex: 1 1 auto;
}

#headerbar {
  background: #222;
  flex: 0 1 auto;

  display: flex;
  align-items: center;

  &>span {
    display: flex;
    align-items: center;
  }

  img {
    height: 2em;
    margin-left: .5em;
  }

  h1 {
    margin: 0.3em;
  }

  #about-button {
    border-radius: 10em;
    border: none;
    margin: 0 .5em;
    width: 2em;
    height: 2em;
    background: #eee;
    font-weight: bold;
    cursor: pointer;
  }

  #show-changesets-button {
    background: url(overflow-menu.svg);
    background-size: contain;
    height: 2em;
    border: none;
    width: 2em;
    margin: 0 .5em;
    cursor: pointer;
  }

  .odometer {
    vertical-align: unset;
  }
}

#main-content {
  position: relative;
  flex: 1 1 auto;

  /* shouldn't be necessary due to the height: 100% in #mapview, but this is a
     workaround for (I think) a webkit bug */
  display: flex;
  flex-direction: column;

  #mapview {
    height: 100%;
    background: none;
    z-index: 0;

    flex: 1 1 auto;

    .leaflet-control-attribution {
      color: #eee;
      background: rgba(0, 0, 0, 0.7);
    }

    .changeset-dot {
      @keyframes changeset-dot-flash {
        0% {
          fill: orangered;
        }
        50% {
          fill: white;
        }
        100% {
          fill: orangered;
        }
      }

      @keyframes changeset-dot-fade {
        from {
          fill-opacity: 0.75;
        }
        to {
          fill-opacity: 0;
        }
      }

      animation-name: changeset-dot-flash, changeset-dot-fade;
      animation-duration: .5s, 600s;
    }
  }

  #changeset-list {
    position: absolute;
    width: 300px;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
  }
}

#changeset-list .changeset-row, #mapview .leaflet-popup-content-wrapper {
  background-color: #555E;
  border-radius: .7em;
  color: #eee;

  a.changeset-comment {
    color: #eee;
    text-decoration: none;
    &:visited {
      color: #ccc;
    }
  }
}

#mapview .leaflet-popup-tip {
  background-color: #555E;
}

#changeset-list .changeset-row {
  padding: .5em;
  margin: .5em;
}

.dialog-wrapper {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .6);
  z-index: 2;
  overflow: auto;

  &.dialog-shown {
    display: unset;
  }

  .dialog {
    background: #444;
    margin: 1em auto;
    width: 80%;
    padding: 1em;
    border-radius: 1em;
    font-size: 1.5em;
    max-width: 30em;

    h2 {
      margin-bottom: .2em;
    }
  }
}

.mobile-only {
  display: none;
}

@media screen and (max-width: 600px) {
  .mobile-only {
    display: unset;
  }

  #headerbar {
    flex-direction: column;
    align-items: stretch;
  }

  #main-content {
    #changeset-list {
      width: 100vw;
      background: rgba(0, 0, 0, .6);

      transition: transform .5s;
      &:not(.changeset-list-shown) {
        transform: translate(100vw);
      }
    }
  }

  .dialog-wrapper .dialog {
    margin-top: 20%;
  }
}
